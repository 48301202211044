@import "../../../globals";

.dashboardGrid {
  height: 90%;
  display: grid;
  gap: var(--veles-layout-padding);

  /*grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  @media (max-width: 1300px) {*/
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  //}
  @media (max-width: 950px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}

.dashboardWarning {
  border: thin solid var(--veles-color-error);

  > span {
    font-size: 1.2em;
    font-weight: 800;
  }

  > p {
  }
}

@use "@fontsource/exo-2/scss/mixins" as Exo2;
@import "./globals";

@include Exo2.fontFaceVariable($type: "wghtOnly");
$fontMain: "Exo 2Variable", "Exo 2", sans-serif;

* {
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100vh;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: $fontMain;
  background-color: var(--veles-color-background);
  color: var(--veles-color-foreground);
}

:root {
  --veles-color-error: #e3373e;
  --veles-color-background: #f2f2f2;
  --veles-color-background-transparent: #f2f2f2f0;
  --veles-color-foreground: #0d0d0d;
  --veles-color-border: #cccccc;
  --veles-color-border-highlight: #9b9b9b;
  --veles-color-surface: #00000008;
  --veles-color-accent: #0ebd0e;

  --veles-color-red: #e3373e;
  --veles-color-blue: #37a7e3;
  --veles-color-green: #3aec3a;

  --veles-layout-padding: 20px;
  --veles-layout-padding-inverse: -20px;
  --veles-layout-padding-slim: 10px;
  --veles-layout-padding-wide: 40px;
  --veles-layout-border-radius: 10px;
}

/*@media(prefers-color-scheme: dark) {
  :root {
    --veles-color-background: #0d0d0d;
    --veles-color-background-transparent: #0d0d0df0;
    --veles-color-foreground: #fff;
    --veles-color-border: #1c1c1c;
    --veles-color-border-highlight: #464646;
    --veles-color-surface: #ffffff08;
    --veles-color-accent: #3aec3a;
  }
}*/

h1,
h2,
h3,
h4,
h5,
h6 {
  &:first-child {
    margin-top: 0;
  }
}

a {
  color: inherit;
  text-decoration: underline dotted currentColor;
}

@import "../globals";

.auth {
  position: absolute;
  width: 100%;
  height: 100%;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: linear-gradient(235deg, #babc4a 0%, #000000 100%),
    linear-gradient(235deg, #0026ac 0%, #282534 100%),
    linear-gradient(235deg, #00ffd1 0%, #000000 100%),
    radial-gradient(
      120% 185% at 25% -25%,
      #eeeeee 0%,
      #eeeeee 40%,
      #7971ea calc(40% + 1px),
      #7971ea 50%,
      #393e46 calc(50% + 1px),
      #393e46 70%,
      #222831 calc(70% + 1px),
      #222831 100%
    ),
    radial-gradient(
      70% 140% at 90% 10%,
      #f5f5c6 0%,
      #f5f5c6 30%,
      #7da87b calc(30% + 1px),
      #7da87b 60%,
      #326765 calc(60% + 1px),
      #326765 80%,
      #27253d calc(80% + 1px),
      #27253d 100%
    );
  background-blend-mode: overlay, lighten, overlay, color-burn, normal;
}

.container {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 700px;

  > .inner {
    background: var(--veles-color-background);
    color: var(--veles-color-foreground);
    border-radius: 10px;
    padding: var(--veles-layout-padding);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    overflow: hidden;
    box-shadow: 2px 1px 40px rgba(black, 0.5);
  }

  > footer {
    padding: var(--veles-layout-padding);
    color: white;
    text-align: center;
    opacity: 0.5;
  }

  @media (max-width: 700px) {
    border-radius: 0;
  }

  h1 {
    margin-bottom: var(--veles-layout-padding);
  }

  h2 {
    margin-top: 0;
    margin-bottom: var(--veles-layout-padding);
  }

  .splitChoice {
    width: calc(100% + 40px);
    margin: -20px;
    margin-top: var(--veles-layout-padding);
    display: flex;

    > a {
      padding: var(--veles-layout-padding);
      width: 50%;
      color: var(--veles-color-foreground);
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;

      > svg {
        width: 60px;
        height: 60px;
        stroke-width: 1;
        margin-bottom: var(--veles-layout-padding);
      }

      &:first-child {
        border-right: thin solid var(--veles-color-border);
      }
    }
  }
}

@import "../../globals";

.error {
  margin: var(--veles-layout-padding);
  color: var(--veles-color-error);
}

.loader {
  display: flex;
  flex-direction: column;
  > svg {
    width: 100px;
    height: 100px;
    stroke-width: 1;

    animation-timing-function: ease-in-out;
    animation-name: spinny-spin;
    animation-iteration-count: infinite;
    animation-duration: 2s;
    margin: var(--veles-layout-padding);
  }
}

@keyframes spinny-spin {
  0% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.authForm {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 500px;
  border-radius: var(--veles-layout-border-radius);
  border: thin solid var(--veles-color-border);
  overflow: hidden;
  background-color: var(--veles-color-surface);
  margin-bottom: var(--veles-layout-padding);

  > input,
  > button {
    padding: var(--veles-layout-padding-slim) var(--veles-layout-padding);
    background-color: transparent;
    color: inherit;

    border: none;
    border-bottom: thin solid var(--veles-color-border);
    font: inherit;

    margin: 0;

    &:focus {
      background-color: var(--veles-color-surface);
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.mindChangedLink {
  opacity: 0.5;
}
